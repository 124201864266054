.p-component {
    @include roboto-normal-font;
}

.p-tooltip-text {
    color: $text-color-default !important;
    background-color: $background-color-yellow-light !important;
}

.landingpage-page-content {
    padding-top: 35px;
    margin-top: 0px;
    text-align: center;
    &.p-grid {
        margin-right: 0;
        margin-left: 0;    
    }

    .top-content {
        padding-left: 35px;
        .top-content-wrapper {
            color: $text-color-white;
            .actions,
            .kotitalousvahennys,
            .price-wrapper {
                @include tablets-and-phones {
                    display: none;
                }
            }
            
            @include desktop {
                max-width: 250px;
                margin: auto;
                position: sticky;
                top: calc(0.5rem + 35px); // This in total padding of content and header
                
                .price-wrapper {
                    .slot-machine-wrapper {
                        margin-right: auto;
                        margin-left: auto;
                        display: inline-block;
                    }
                    margin-top: 60px;
                    color: $background-color-yellow;
                    .price-text {
                        font-size: 20px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 26px;
                        
                        &.vat {
                            display: block;
                            font-size: 9px;
                            line-height: 16px;
                        }
                    }
                    .price {
                        position: relative;
                        &:before{
                            content: url('../../static/img/Star.svg');
                            bottom: 33%;
                            position: absolute;
                            left: 10%;
                        } 
                        &:after {
                            content: url('../../static/img/Star.svg');
                            bottom: 33%;
                            position: absolute;
                            right: 10%;
                        }

                    
                        font-size: 47px;
                        letter-spacing: 0;
                        line-height: 47px;
                        padding-top: 7px;
                        padding-bottom: 4px;
                        height: 64px;
                    
                        .decimals {
                            font-size: 25px; 
                        }
                    }
                    .discount-applied:not(&.block-price) {
                        margin-bottom: 15px;
                        font-size: 26px;
                        text-decoration: line-through;
                        
                        #counter {
                            position: relative;
                            margin-bottom: 0px;
                            font-size: 26px;
                            line-height: 26px;
                            height: 26px;
                            text-decoration: line-through;

                            &:after  {
                                height: 2px;
                                width: 100%;
                                background-color: $background-color-yellow;
                                position: absolute;
                                left: 0;
                                bottom: 45%;
                                right: 10%;
                                content: "";   
                            }
                        }

                    }
                    .divider-top {
                        padding-top: 3px;
                        border-bottom: 2px solid $background-color-yellow;
                        width: 100%;
                        position: relative;
                        margin-top: 10px;

                        &:before {
                            content: "";
                            background: $background-color-yellow;
                            position: absolute;
                            top: -3px;
                            left: 7%;
                            height: 2px;
                            width: 86%;     
                        }
                    }

                    .divider-bottom {
                        padding-bottom: 3px;
                        border-top: 2px solid $background-color-yellow;
                        width: 100%;
                        position: relative;

                        &:after {
                            content: "";
                            background: $background-color-yellow;
                            position: absolute;
                            top: 3px;
                            left: 7%;
                            height: 2px;
                            width: 86%;     
                        }
                    }

                }

                .kotitalousvahennys {
                    margin-top: 15px;
                    span {
                        color: $background-color-yellow;
                        &.hdc-link {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }

                .actions {
                    .order-button {
                        @include primary-button;
                    }
                    .star {
                        margin-top: 36px;
                    }

                    .check-free-time.sidebar {
                        margin-top: 18px;
                        margin-bottom: 0;
                        padding: 5px;
                    }
                    .discount-block {
                        padding: 5px;
                    }
                }
            }

            .top-content-text {
                max-width: 250px;
                margin: 26px auto 0;
                color: $background-color-yellow;
            }
        }
    }

    .shopping-mall-content { // SHOPPING MALL EDITIONS

        .mall-content-wrapper {
            .mall-windows-wrapper, .mall-cleaning-sets-wrapper {
                display: flex;
                width: 100%;
                justify-content: center;
                &:not(.mall-windows-wrapper) {
                    flex-flow: wrap;
                }
            }
        }

        .mall-cleaning-sets {
            overflow: hidden; // Made to make suosituin flag overflown hidden.
            min-width: 290px;
            height: 431px!important;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            background-color: $background-color-yellow-light;
            padding: 0;
            margin: 0 15px 40px 10px!important;
            position: relative;
            .title {
                padding-top: 25px;
                &.ylläpito {
                    color: #593400!important;
                }           
                &.perus {
                    color: #E0A809!important;
                }           
                &.loisto {
                    color: #48BBDF!important;
                }           
            }

            .image-container {
                height: 60px;
            }

            .ad-text,.extra-text {
                font-family: 'Roboto Slab';
                font-style: normal;
                font-weight: 500;
                text-align: center;
                color: #593400;
                &:not(.extra-text) {
                    padding-left: 45px;
                    padding-right: 45px;
                }
            }
            .ad-text {
                padding-top: 20px;
                font-size: 14px;
                line-height: 18px;
            }
            .extra-text-wrapper {
                position: relative;
                border-radius: 5px;
                height: 54px;
                margin-left: 15px;
                margin-right: 15px;
                .extra-text {
                    font-size: 15px;
                    line-height: 48px;
                    text-align: center;
                    &.ylläpito {
                        color: #979393;
                    }
                }
                .value-banner-wrapper {
                    width: 90px;
                    height: 22px;
                    position: absolute;
                    left: calc(50% - 45px);
                    bottom: -11px;
                    background-color: #F7CA4B;
                    border-radius: 32px;    
                    padding: 2px 12px;
                    // gap: 8px; // Oli leiskassa ?
                    .value-banner {
                        width: 66px;
                        height: 18px;
                        font-size: 14px;
                        line-height: 18px;
                        color: #000000;
                        white-space: nowrap;
                    }
                }
                .value-star-wrapper {
                    position: absolute;
                    width: 35px;
                    height: 20px;
                    left: calc(50% - 17.5px);
                    top: -10px;
                    background-color: #FDF6DF;
                    &.perus {
                        // background-color: #FDF6DF;
                    }
                    &.loisto {
                        width: 70px;
                        left: calc(50% - 35px);
                        color: #4DBFE3;
                        img:first-child {
                            margin-right: 10px;
                        }
                        img { // Make image first black and then convert to as close as possible to #4DBFE3. Loss percentage 1.8%.
                            filter: brightness(0) saturate(100%) invert(79%) sepia(25%) saturate(2688%) hue-rotate(163deg) brightness(92%) contrast(93%);
                        }
                    }
                }
                &.perus {
                    border: 2px dashed #F7CA4B;
                    .value-banner-wrapper {
                        background-color: #F7CA4B;
                    }
                }
                &.loisto {
                    border: 2px dashed #4DBFE3;
                    .value-banner-wrapper {
                        background-color: #93E5FF;
                    }
                }
            }
            .select-button-wrapper {
                position: absolute;
                bottom: 20px!important;
                width: 89px!important;
                left: calc(50% - 45px);
                .cleaning-set-button:not(.selected) {
                    background-color: $background-color-yellow-light!important;
                    border: 2px solid $text-color-yellow!important;
                }
                &.selected {
                    left: calc(50% - 55px);
                }
            }
            .mall-suosituin-flag {
                position: absolute;
                width: 182.41px;
                height: 20px;
                left: -50px;
                top: 22px;

                background: #F7CA4B;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.11);
                transform: rotate(-39.92deg);
                span {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px; // identical to box height
                    color: #593400;
                    transform: rotate(-39.92deg);
                }
            }
        }
        .mall-order-includes-tab-wrapper {
            padding: 15px;
            background-color: $background-color-yellow-light;
            // @include sansita-swashed-font;
            @include roboto-normal-font;
            color: #593400!important;
            font-size: 14px;
            line-height: 30px;
            .sub-title {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .counter-windows {
        
        @include tablets-and-phones {
            max-width: 800px;
            margin: auto;

            .p-accordion{
                margin-top: 50px;
            }
        }
        @include desktop {
            padding-right: 35px;    
            padding-left: 35px;
            padding-top: 0;
        }
        
        .p-accordion{
            width: 100%;

            .p-accordion-tab{
                margin-bottom: 10px;
                background-color: $background-color-yellow;
                border-radius: 4px;

                .p-accordion-header {
                    margin-bottom: 0;
                    &.p-highlight {
                        .p-accordion-header-text {
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                            border-bottom: 1px solid $text-color-light-brown;
                            padding-bottom: 10px;
                        }
                        .p-accordion-toggle-icon{
                            margin-bottom: 11px;
                        }
                    }
                    .p-accordion-header-link,
                    &:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
                    &:not(.p-highlight):not(.p-disabled):hover a,
                    a:hover,
                    &:hover  {
                        background-color: $background-color-yellow;
                        border-radius: 4px;
                        box-shadow: none;
                        border: none;
                        
                    }

                    a {
                        min-height: 58px;
                        box-shadow: none;
                        margin-bottom: 0px;
                        align-items: left;
                        text-align: left;

                        .p-accordion-toggle-icon.pi.pi-chevron-down::before,
                        .p-accordion-toggle-icon.pi.pi-chevron-right::before {
                            background-color: rgb(239, 182, 0);
                            color:#000000;
                            border-radius: 50%;
                            padding: 7px;
                            padding-top: 8px
                        }
                        .p-accordion-header-text {
                            border: none;
                            color: #000000;
                            border-radius: 4px;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            width: 100%;
                            font-weight: 600;

                            .tab-tile {
                                display: block;
                                min-height: 44px;
                                .left {
                                    float: left;
                                    .title {
                                        width: 100%;
                                        font-size: 16px;
                                        line-height: 40px;
                                        &.mall-name {
                                            font-size: 14px;
                                            .dropdown-button {
                                                background: transparent;
                                                border: none;
                                                cursor: pointer;
                                                i:hover {
                                                    color: $hover-color-yellow;
                                                }
                                            }
                                        }
                                    }
                                    .description {
                                        font-weight: 400;
                                        &:before {
                                            content: "\A";
                                            white-space: pre;
                                        }
                                    }
                                &.right {
                                    float: right;
                                }
                                }
                                
                                .block-price {
                                    float: right;
                                    color: $text-color-light-brown;
                                    font-size: 22px;
                                    line-height: 29px;
                                    text-align: right;
                                    font-weight: 400;
                                    
                                    @include desktop{
                                        font-size: 38px;
                                        font-weight: 300;
                                        letter-spacing: 0;
                                    }

                                    #counter {
                                        margin-right: 15px;
                                        font-size: 38px;
                                        font-weight: 300;
                                        letter-spacing: 0;
                                        line-height: 38px;
                                        text-align: right;
                                        height: 38px;
                                    }
                                    &.discount-applied {
                                        font-size: 19px;
                                        text-decoration: line-through;
                                        margin-right: 10px;
                                        padding-top: 5px;    
                                        margin-bottom: 0;
                                    }
                                    &.discount-price {
                                        //margin-right: 15px;
                                        font-size: 38px;
                                        font-weight: 300;
                                        letter-spacing: 0;
                                        line-height: 38px;
                                        text-align: right;
                                        height: 38px;
                                    }
                                    .decimals {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }

                    &:not(.p-disabled){
                        a:focus {
                            box-shadow: none;
                        }
                        .p-highlight:hover a {
                            background-color: $background-color-yellow;
                            border: none;
                        }

                    } 
                }
                .p-accordion-header a,
                .p-accordion-tab {
                    border-radius: 4px;
                    //box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
                    background-color: $background-color-yellow;
                    //margin-bottom: 20px;
                    border: none;
                }
            
                .p-toggleable-content .p-accordion-content {
                    background-color: $background-color-yellow;
                    border-radius: 4px;
                    border: none;
                    text-align: left;
                    
                    .ac-tab-content-wrapper {
                        @include phones {
                            .p-justify-start {
                                justify-content: center;
                            }
                        }
                        .accordion-tab-col {
                            text-align: center;
                            min-height: 320px;
                            width: 250px;
                            max-width: 250px;
                            margin: 0 35px 40px 30px;
                            padding: 0;

                            &.conditionally-visible {
                                min-height: auto;
                            }
                            //position: relative;
                            .title {
                                @include sansita-swashed-font;
                                margin-bottom: 30px;
                                min-height: 54px;
                                &:first-letter {
                                    text-transform: capitalize;
                                }
                                &.window {
                                    max-width: 230px;
                                    margin-left: auto;
                                    margin-right: auto;
                                    &.high-window {
                                        margin-bottom: 10px;
                                    }
                                }
                                &.terrace {
                                    min-height: 54px;
                                    margin-bottom: 0;
                                }
                                &.misc {
                                    margin-bottom: 0;
                                }
                            }
                            .window-over-six-meters {
                                height: 20px; // 20px combined with high-window-title 10px equals to 30px (other window titles.)
                                text-decoration-line: underline;
                                cursor: pointer;
                            }
                            .image {
                                margin-bottom: 30px;
                                width: 250px;
                                img {
                                    height: 149px;
                                    //width: 120px;
                                    width: auto;
                                }
                                &.misc {
                                    margin-bottom: 0;
                                    img {
                                        height: 209px; // This is additional 30 + 30 to default 149px, from reduced margin of title and image classes.
                                    }
                                }
                                &.info {
                                    margin-bottom: 0;
                                }
                            }
                            .window-info-text {
                                margin-bottom: 15px;
                                min-height: 38px;
                                color: #000000;
                            }
                            .ac-tab-content-action-wrapper:not(.additional-info) {
                                //position: absolute;
                                bottom: 0;
                                background-color: $text-color-yellow;
                                border-radius: 10px;
                                width: 250px;
                                height: 50px;
                                .btn {
                                    @include primary-button;
                                    border: none;
                                    margin-top: 0px;
                                    width: auto;
                                    &:focus {
                                        outline: none;
                                    }
                                    i {
                                        margin-right: 0;
                                        color: #000000;
                                    }
                                }
                                .minus-btn,
                                .plus-btn {    
                                    padding-left: 32.25px;
                                    padding-right: 31.25px;
                                    .p-button-label {
                                        display: none;
                                    }
                                }
                                .minus-btn {
                                    float: left;
                                }
                                .plus-btn {
                                    float: right;
                                }
                                .amount {
                                    background-color: #ffffff;
                                    border: none;
                                    height: 40px;
                                    margin-top: 5px;
                                    border-radius: 4px;
                                    text-align: center;
                                    width: 89px;
                                    color: #000000;
                                    font-size: 22px;
                                    letter-spacing: 0;
                                    font-weight: 600;
                                    
                                }

                                &.select {
                                    background-color: transparent;
                                    .selectButton {
                                        @include primary-button;
                                        width: 120px;
                                        margin-top: 0px;
                                        border: 2px solid $text-color-yellow;
                                        border-radius: 10px;
                                        background-color: #FFF2C4;

                                        &.checked {
                                            background-color: $text-color-yellow;
                                        }
                                        &:first-of-type {
                                            margin-right: 10px;
                                        }

                                        &:enabled:hover {
                                            border: 2px solid $hover-color-yellow;
                                        }                                        
                                    }
                                } 
                                //&.high-window-amount-wrapper,
                                &.balcony-meter-wrapper {
                                    position: relative;
                                    &::after {
                                        content: '*';
                                        position: absolute;
                                        top: 10px;
                                        right: 93px;
                                        font-size: 20px;
                                        color: black;
                                    }
                                }
                                
                                &.balcony-meter-wrapper:after {
                                    content: "m";
                                    top: 15px;
                                }
                            }
                            .section-text {
                                font-size: 16px;
                                letter-spacing: 0;
                                line-height: 21px;
                                text-align: center;    
                                margin: 23px 0;
                                color: $background-color;
                                &.first {
                                    position: relative;
                                    margin-top: 0;
                                    &:before {
                                        content: '*';
                                        font-size: 16px;
                                        font-weight: bold;
                                        position: absolute;   
                                        color: black;
                                        top: -4px;
                                        left: 13px;
                                    }
                                }
                                .telephone {
                                    color: $text-color-light-brown;
                                }
                            }
                        }

                        .marketing-block {
                            text-align: center;
                            align-items: center;
                            margin-top: 10px;
                            .text {
                                color: #000000;
                                font-size: 16px;
                                letter-spacing: 0;
                                line-height: 21px;
                                @include desktop {
                                    padding-left: 0;
                                }
                                .box {
                                    @include phones {
                                        text-align: justify;
                                    }
                                }
                            }
                        }

                        &.additional-info {
                            .accordion-tab-col {
                                min-height: auto;
                                width: inherit;
                                max-width: 90%;
                                margin: 0 35px 40px 30px;
                                @include phones {
                                    max-width: 100%;
                                    margin-top: 0;
                                    min-height: 0;
                                }
                                
                                .textarea-additional-info {
                                    &::placeholder {
                                        color: $text-color-light-brown;
                                    }
                                    width: 100%;
                                    min-height: 200px;
                                    max-width: 100%;
                                    border-radius: 10px;
                                    font-size: 16px;
                                    font-weight: 500;
                                    letter-spacing: 0;
                                    line-height: 21px;
                                }
                            }
                        }
                    }

                }
            }
            .p-accordion-tab-active{
                .additional-header {
                    .p-accordion-header-link {
                        padding-bottom: 0;
                    }
                    .p-accordion-header-text {
                        border-bottom: none!important;
                    }
                }
            }
        }
    }
    

}
.bottom.star {
    margin-top: 25px;
    @include desktop {
        display: none;
    }
}
.discount-block, 
.check-free-time {
    @include desktop {
        &.bottom {
            display: none;
        }
    }
    button, 
    a {
        background: transparent;
        border: none;
        color: $background-color-yellow;
        height: 21px;
        font-family: "Roboto Slab";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
        &:focus {
            border: none;
            box-shadow: none;
            outline: none;
        }
    }
}
.check-free-time {
    margin-top: 15px;
    margin-bottom: 0px;
}
.discount-block {
    &.bottom {
        margin-bottom: 100px;
        .discount-action {
            margin-bottom: 40px;
            label {
                color: #FFFFFF;
            }
        }
    }
    .discount-action {

        .title {
            @include page-title;
            font-size: 26px;
            line-height: 32px;
            margin: 0 auto 13px;
        }
        label {
            color: #FFFFFF;
            width: 100%;
            display: block;
        }
        input {
            margin-top: 20px;
            height: 48px;
            border: 1px solid $input-border-color;
            border-radius: 4px;
            background-color: #FFFFFF;
            &:focus, &:active {
                box-shadow: none;
                border: none;
            }
        }
        .pi {
            font-size: 2em;
            top: 56%;
            &.pi-spinner {
                color: $text-color-light-brown;
            }
            &.pi-check {
                color: green;
            }
            &.pi-ban {
                color: $error-color;
            }
        }
    }
}

.postalcode-validation {
    .p-dialog-content {

        padding-bottom: 0;
        .box{
            @include kl-inputtext();
            padding: 0.5em;
        }

    }
    .p-dialog-footer button:disabled { 
        background: $text-color-yellow!important;
        border-color: $text-color-yellow !important;
    }

}