
// Breakpoints
$bp-desktop: 992px;


// Colors
$header-back-color: #2C2221;
$footer-background-color: #2b2221;
$background-color: #402D28;
$background-line-color:#A08468;
$background-color-yellow: #FFF2C4;
$background-color-yellow-light: #FDF6DF; // SHOPPING MALL EDITIONS
$success-page-yellow: #FFEDB9;

$text-color-default: #000000;
$text-color-white: #FFFFFF;
$text-color-light-brown: #A08468;
$text-color-yellow: #FFC91D;
$hover-color-yellow: #FFDC6D;
//$hover-color-yellow: #ffc107;
$text-color-light-yellow: #FFF3D1;
$calendar-date-border: #FFE793;
$calendar-today-background: #553300;
$calendar-selected-background: #E28800;
$error-color: #FF2626;

$color-light-blue: #CFFFFE;


$input-border-color: #979797;
