.order-confirmation-page {
    text-align: center;

    .page-head {
        .order-confirmation-title {
            @include page-title;
        }
    }

    .order-confirmation-wrapper {
        .time .shipping-date-row {    
            color: $text-color-white;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
            text-align: center;
            margin-bottom: 20px;
            display: grid;
            img {
                display: none;
            }
        }
    }

    .summarize {
        @include tablets-and-phones {
            display: block;
        }
        max-width: 450px;
        margin-right: 0;
        .summarize-content {
            background-color: $background-color-yellow;

            .title span {
                display: none;
            }
        }
    }

    .submit {
        width: 100%;
        .box {
            max-width: 450px;
            margin: auto;
            padding: 0 0.5rem;
            .p-button {
                height: 50px;
                border: 1px solid;
                width: 100%;
                border-radius: 10px;

                .p-button-label {
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                    text-align: center;
                }
            }
        }
    }
}