
.p-grid {
    margin-right:0 ; 
    margin-left: 0;
}

.calendar-page .summarize {
    display: none;
}
.order-confirmation-page,
.address-page,
.calendar-page {
    text-align: center;

    .page-head {
        .calendar-page-title {
            @include page-title;
            // margin: 67px auto 29px; // Commented out to remove big top margin. Did not find out reason for this rule. -Olli
            max-width: 460px;
            &.addr { 
                padding-left: 25px;
                max-width: 1200px;
            }
            
        }
    }
    .calendar-postalcode {
        .box {
            max-width: 460px;
            margin: 0 auto;
            background-color:$background-color-yellow;
            border-radius: 10px;
            padding: 25px;

            .postal-code-box {
                display: flex;
                align-items: center;
                justify-content: space-around;
                position: relative;
                max-width: 200px;
                margin: 0 auto;
                label {
                    // left: 15px;
                    color: $input-border-color
                }
            }
        }
    }

    .summarize {
        @include tablets-and-phones {
            display: none;
        }
        margin-right: 12px;
        padding-top: 0;
        max-width: 350px;
        .summarize-content {
            background-color:$background-color-yellow;
            border-radius: 10px;
            padding: 0 17px 40px 27px;
            .title {
                @include sansita-swashed-font;
                color: $background-color;
                font-size: 30px;
                letter-spacing: 0;
                line-height: 36px;
                text-align: center;
                padding-top: 30px;

                .title-2 {
                    @include roboto-normal-font;
                    color: #000000;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                    text-align: center;
                    margin-top: 12px;
                }
            }

            .window-amounts {
                margin-top: 25px;
                padding-bottom: 25px;
                border-bottom: 1px solid $background-line-color;
                margin-bottom: 21px;
                
                .window-sum-row {
                    width: 100%;
                    clear:left;
                    padding-top:5px;
                    padding-bottom: 15px;
                    font-size: 14px;
                    
                    .window-name{
                        float: left;
                        max-width: 220px;
                        text-align: left;
                    }
                    .window-amount{
                        float: right;
                    }
                }
            }

            .total-row,
            .discount-row {
                width: 100%;
                clear:left;
                padding-bottom: 0px;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                display: inline-block;
                
                .total-text,
                .discount-text {
                    float: left;
                }
                .discount-text {
                    font-weight: 400;
                    max-width: 210px;
                    text-align: left;
                    text-decoration: underline;
                    &.hhd {
                        cursor: pointer;
                    }
                }

                .discount-sum {
                    font-weight: 400;
                }
                .total-sum,
                .discount-sum {
                    float: right;
                }
            }

            .total-row{
                font-size: 14px;
            }
            .shipping-date-row {
                align-items: center;
                display: block;
                img {
                    margin: 30px auto 15px;
                    display: block;
                    width: 69px;
                    height: 59px;

                }
                span {
                    margin: auto;
                    display: block;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 22px;
                    text-align: center;
                    &:first-of-type{
                        font-size: 15px;
                        font-weight: 600;
                    }
                    &.date {
                        font-weight: 600;
                    }
                }
            }

        }
        .alv-info {
            color: $background-line-color;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 19px;
            margin-top: 16px;
            text-align: center;
            font-weight: 300;
        }
    }
    .mobile-summarize {
        .summarize {
            margin-top: 20px;
            display: block;
            max-width: 100%;
            width: 100%;
            @include desktop() {
                display: none;
            }
            .title {
                /*span {
                    display: none;
                }*/
                .title-2 {
                    margin-top: 0px;
                }
            } 
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.postal-wrapper-box {
    position: relative;
    .cal-prev-btn {
        z-index: 999;
        position: absolute;
        top: -73px;
        left: 0;

        border-radius: 50%;
        background: transparent;
        float: left;
        margin-top: 5px;
        padding: 0;
        height: 35px;
        width: 35px;
        border: none;
        outline: none;
        &:hover {
            cursor:pointer; 
        }

        .prev-page-icon {
            &:before {
                content: url('../../static/img/Icon_haitari.svg');
                transform: rotate(90deg);
                display: block;
            }
        }
    }
}
.address,
.calendar {
    background-color: $background-color-yellow;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 100px;
    position: relative;

    .addr-prev-btn {
        z-index: 999;
        position: absolute;
        top: -73px;
        left: 0;

        border-radius: 50%;
        background: transparent;
        float: left;
        margin-top: 5px;
        padding: 0;
        height: 35px;
        width: 35px;
        border: none;
        outline: none;
        &:hover {
            cursor:pointer; 
        }

        .prev-page-icon {
            &:before {
                content: url('../../static/img/Icon_haitari.svg');
                transform: rotate(90deg);
                display: block;
            }
            /*&:focus, &:hover {
                border-radius: 50%;
                outline: 0 none;
                outline-offset: 0;
                -webkit-box-shadow: 0 0 0 0.2rem #ffe69c;
                box-shadow: 0 0 0 0.2rem #ffe69c;
            }*/
        }
    }

    .cal-prev-btn {
        top: -178px;
    }
    
    @include desktop{
        .addr-prev-btn {
            left: -357px;
        }
    }
    @include tablets-and-phones{
        padding: 5px;
    }
    .calendar-content,
    .address-content {
        .p-calendar {
            .p-datepicker {
                background: none;
                border:none;
                padding: 0;
                .p-datepicker-header {
                    border:none;
                    background: none;
                    .p-datepicker-prev-icon.pi.pi-chevron-left {
                        &:before {
                            content: url('../../static/img/Icon_haitari.svg');
                            transform: rotate(90deg);
                            display: block;
                        }
                    }
                    .p-datepicker-next-icon.pi.pi-chevron-right {
                        &:before {
                            content: url('../../static/img/Icon_haitari.svg');
                            transform: rotate(-90deg);
                            display: block;
                        }
                    }

                    .p-datepicker-title {
                        color: #000000;
                        font-size: 22.4px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 29px;
                        text-align: center;
                        @include desktop{
                            min-width: 325px;
                        }
                    }
                }

                .p-datepicker-calendar {
                    // hide saturday and sunday columns
                    tbody tr td:nth-last-of-type(-n+2),
                    thead tr th:nth-last-of-type(-n+2) {
                        display:none;
                    }

                }
                table {

                    @include desktop{
                        max-width: 400px;
                    }
                    color: #000000;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                    text-align: center;
                    margin: auto;
                    th {
                        padding: 30px 0 15px;
                    }
                    td {
                        padding: 3px;
                        max-width: 80px;
                        &> span {
                            border-radius: 0;
                            border: none;
                            width: auto;
                            height: auto;

                            &:focus {
                                box-shadow: none;
                            }
                            
                            .date-col {
                                @include tablets-and-phones {
                                    width: 60px;
                                    height: 50px;
                                }
                                width: 74px;
                                height: 58px;
                                border: 2px solid $text-color-yellow;
                                color:  #000000;
                                text-align: left;
                                font-size: 16px;
                                font-weight: 600;
                                letter-spacing: 0;
                                line-height: 21px;
                                padding-top: 4px;
                                padding-left: 10px;
                                background-color: $text-color-yellow;
                            }
                            &.p-disabled {
                                opacity: 1;
                                .date-col { 
                                    border: 2px solid $calendar-date-border;
                                    background-color: $background-color-yellow;
                                    color: $text-color-light-brown;
                                }
                            }
                            &.p-highlight {
                                .date-col {
                                    background-color: $color-light-blue;
                                    color:$calendar-today-background;
                                }
                            }
                        }
                        &.p-datepicker-today {
                            > span {
                                background: $color-light-blue;
                                .date-col {
                                    background-color: $calendar-date-border!important;
                                    //color: #ffffff!important;
                                }
                            }
                        }
                
                    }
                }
            }
            &:not(.p-disabled) table td span:not(.p-disabled):hover {
                background: transparent;
                .date-col {
                    background-color: $color-light-blue;
                    color: $calendar-today-background;
                }
            }
            &:not(.p-disabled) table td span:not(.p-disabled):focus {
                border: none;
                box-shadow: none;
            }
        }        
    }
    .selected-date {
        margin-top: 10px;
        margin-bottom: 5px;
        text-align: center;
        > span {
            &.date {
                font-weight: 600;
            }
            &.info-text {
                font-size: 14px;
                margin-top: 5px;
            }
            display: block;
            color: #000000;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
        }
        .p-button {
            @include primary-button;
            min-width: 320px;
            margin-top: 20px;
        }
    }
}