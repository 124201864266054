/**
 * Quick and easy CSS3 rolling-number/slot machine?
 * https://gist.github.com/iokaravas/8274608
 */

#counter {
   height: 47px;
   line-height: 47px;
   font-size: 47px;
   overflow: hidden;
   margin-right: auto;
   margin-left: auto;
   display: inline-block;
}

.digits {
   float:left;
   list-style-type: none;
   font-size: 1em;
   line-height: 1em;
   padding-left: 0px;

   animation-duration: var(--data-duration);
   animation-timing-function: ease-in-out;
   animation-delay: 0s;
   animation-fill-mode: forwards; 
}

.luckie {
   animation-name: luckie;
}

/* Animations */
@keyframes luckie {
   0% {
       margin-top: var(--data-start) ;
   }
   100% {
       margin-top: var(--data-end) ;
   }
}
