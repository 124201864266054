body{
    background-color: #FFF!important;
     .admin-root{
        color: #495057;
        
        .main-container {
            margin: 30px;
            > div {
                padding: 20px;
            }
        }

        .logout-btn-wrapper {    
            .user-name {
                display: inline-block;
                padding: 0.5rem 1rem;
            }
        }


        /* Titles styles */
        .title-h1 {
            font-size: 22px;
            font-weight: 800;
        }
        .title-h2 {
            font-size: 18px;
            font-weight: 600;
        }
        .title-h3 {
            font-size: 14px;
            font-weight: 400;
        }

        /* Content Header */
        .content-header {
            .create-button {
                float: right;
                margin-bottom: 30px;
            }
        }


        /*Form layout*/

        form  {
            margin-top: 30px;
            
            .form-field-error {
                margin-left: 10px;
            }
            .form-error {
                color: red;
            }

            .p-fluid .p-button {
                width: auto;
            }
        }

        .label {
            width: 100%;
        }

        /* Counter Config Form*/

        .cc-general-fields {
            padding-top: 15px;
        }

        .window-row {
            .hidden-img-input {
                display: none;
            }

            .small-field {
                max-width: fit-content;
            }

            .img-col {
                min-width: 120px;
            
                .thumbnail-img {
                    width: 100px;
                    height: 130px;
                    border-radius: 2px;
                    border: 2px solid #ced4da;
                    &:hover {
                        opacity: 0.5;
                        border: 2px solid #FFC107;
                    }
                }
            }
            .remove-window-btn {
                margin-top: 25px;
            }
            label.checkbox-label {
                width: 100%;
            }
            .p-checkbox-box {
                margin-top: 5px;
                padding: 10px;
                margin-left: 15px;
            }
        }

        /* Data table*/

        .p-datatable.p-component {
            margin-top: 75px;
        }

        /* Icons */
        i.pi {
            font-size: 1.2em;
            margin-right: 15px;
            color: #495057;
            
            &:hover,&:focus {
                color: black;
            }
        }

        /* Dialog */
        .p-dialog {

            .delete-email {
                font-weight: 900;
            }
        }

        .admin-calendar {
            display: block;
            float: left;
        }

        .disabled-dates-list {
            display: inline-block;
            padding: 20px;
            span {
                display: block;
            }
            .calendar-time-row {
                margin-top: 20px;
            }
        }

        .p-button.p-component.create-button {
            width: auto;
        }

        .settings-page {
            display: block;
            .settings-menu {
                margin-top: 50px;
                display: inline-block;
                padding-right: 20px;
            }

            .settings-tab {
                margin-top: 50px;
                display: inline-block;
            }
        }
    }
    .p-button-outlined.active {
        background-color: #FFC107!important;
    }
    .markings {
        background-color: red;
    }
    .admin-root .disabled-dates-list span.pi {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        &:hover {
            color: #FFC107;
        }
    }
}
