body .admin-root .settings-page  {
    margin-top: 0px;
    display: inline-block;

    .p-checkbox .p-checkbox-box {
        margin-top: 0px;
        margin-left: 0px!important;
    }

    .p-multiselect.p-component {
        width: 300px;
    }
    
    .p-multiselect-panel .p-multiselect-items { 
        .p-multiselect-item {
            //margin-top: 5px;
            .p-checkbox {
                margin-right: 20px;
            }
        } 
    }

    .remove-row.p-button {
        width: 40px;
        height: 40px;
        display: inline-block;
        margin-left: 10px;
        margin-top: 25px;
    }

    .save-btn .p-button{
        width: auto;
    }

    .tab-content {
        margin-top: 30px;
        .p-grid input {
            margin-right: 10px;
        }

        .action-grid button {
            width: 200px;
            margin-right: 20px;
        }
    }

    .label {
        width: auto;
        display: block;
    }
}

.p-multiselect .p-multiselect-label{
    min-width: 150px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    min-width: 150px;
}



.cleaner-calendar-links {
    > div {
        margin-right: 10px;
    }
}