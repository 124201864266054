 .term-modal {
        .term-modal-container section {
            width: 100%;
            span {
                width: auto;
                font-size: 22px;
                float: left;
                text-align: left;
                padding-left: 40px;
                display: inline-block;
            }
            span.normal-text {
                text-align: left;
                display: inline-block;
                font-size: 16px;
                padding-left: 5px;
            }
            ul {
                margin-bottom: 10px;
                display: inline-block;
                width: 100%;
                li {
                    padding-bottom: 5px;
                    text-align: left;
                    width: 100%;
                }
            }
        }
    }
.address {
    background-color: transparent;
    border-radius: 0px;
    padding: 0;
    margin-bottom: 100px;
   
    form {
        width: 100%;
        > div {
            padding-left: 0px;
            padding-right: 0px;

            .subtitle {
                color: #ffffff;
                text-align: left;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
            }
            &.divider {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                height: 1px;
                background-color: $text-color-light-brown;
            }
            .box{
                &.checkbox {
                    display: flex; // To align multilined text
                }
                position: relative;
            
                .p-inputtext {
                    width: 100%;
                    border-radius: 10px;
                    height: 50px;
                    //background-color: $background-color-yellow!important;

                    color: #000000;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                    padding-top: 19px;
                    padding-left: 20.5px;
                    border: 2px solid #FFFFFF;
                    &#message {
                        height: auto;
                    }
                    &:enabled:hover {
                        border: 2px solid $calendar-date-border;
                        box-shadow: none;
                    }
                    &:enabled:focus{
                        border: 2px solid $hover-color-yellow;
                        box-shadow: none;
                    }
                }
                .placeholder {
                    position: absolute;
                    top: 14px;
                    left: 20.5px;
                    height: 21px;
                    color: $text-color-light-brown;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 21px;

                    &.focus-or-value {
                        top: 7px;
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 13px;
                    }
                }
                .p-inline-message-text  {
                    margin-top: 4px;
                    float: left;
                    color: $error-color;
                    height: 19px;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                }

                &.error {
                    margin-bottom: 18px;
                    .p-inputtext {
                        border: 2px solid $error-color;
                        &:enabled:focus {
                            box-shadow: none;
                        }
                    }
                }
                .message-area::placeholder {
                    padding-top: 16px;
                    padding-left: 0;
                    color: $text-color-light-brown;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 21px;
                  }
                textarea#message {
                    padding-left: 18px;
                }
            }    
            &.postal-code,
            &.postal-city {
                display: inline-block;
            }
            &.postal-city {
                color: #FFFFFF;
                text-align: left;
                padding-left: 20px;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 21px;
                > div > span {
                    position: absolute;
                    top: -13px;
                }
            }
            &.terms {

                height: 50px;       
                 > div {
                    float: left;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 30px;
                    //height: 50px; */
                    text-align: start;

                    .p-checkbox,
                    .p-checkbox-box {
                        height: 30px;
                        width: 30px;
                        border-radius: 6px;
                        border: 0px;
                    }
                    label {
                        margin-left: 14px;
                        a {
                            color: #FFFFFF;
                        }
                    }
                }
                &.privacy-terms {
                    padding-left: 30px;
                }
            }

            &.invoice-channel-wrapper,&.mall-starting-month-wrapper {
                text-align: left;

                .p-dropdown-panel {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    box-shadow: none;
                    top: 40px!important;
                }
                .p-dropdown {
                    border: none;
                    border-radius: 10px;
                    .p-dropdown-items .p-dropdown-item:not(.p-disabled) {
                        @include roboto-normal-font;
                        &:hover,
                        &.p-highlight {
                            background:$text-color-light-yellow;
                        }
                    }
                }
                #invoice_channel,#mall_starting_month {

                    width: 100%;
                    margin-top: 1rem;
                    .p-dropdown-items-wrapper {
                        border: none;
                    }
                    &.p-focus {
                        box-shadow: none;
                        border: none;
                    }
                    div {
                        border: none;
                    }
                    .p-dropdown-label {
                        @include roboto-normal-font;
                        font-size: 16px;
                        line-height: 16px;
                        padding-top: 15px;
                        font-weight: 600;
                    }
                    .p-clickable.pi-chevron-down:before {
                        color: #000000;
                    }
                    .p-placeholder {
                        color: $text-color-light-brown;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }

            &.billing-postal-row {
                display: inline-block;
                padding-right: 0px;              
                padding-left: 5px;
                &.left {
                    padding-right: 5px;              
                    padding-left: 0px;
                }
            }
            &.submit > div {
                .p-button {
                    margin-top: 0px;
                    width: 100%;
                    @include desktop() {
                        width: 327px;
                    }
                }
            }

            &.repeated-order {
                background-color: $success-page-yellow;
                color: #FFFFFF;
                text-align: left;
                border: none;
                border-radius: 10px;
                @include roboto-normal-font;
                padding: 30px;
                margin-bottom: 20px;

                p {
                    display: block;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 22px;
                }
                .checkbox-wrapper {
                    font-weight: 600;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 30px;
                    height: 50px;

                    .p-checkbox,
                    .p-checkbox-box {
                        //background-color: $background-color;
                        height: 30px;
                        width: 30px;
                        border-radius: 6px;
                        border: 0px;
                        vertical-align: middle;
                    }
                    label {
                        margin-left: 14px;
                        a {
                            color: #FFFFFF;
                        }
                    }
                    .change-interval {
                        display: inline-block;
                        margin-left: 5px;
                        a {
                            font-weight: 400;
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 22px;
                            color: #000;
                        }

                        .p-dropdown-items-wrapper {
                            border: none;
                        }
                        &.p-focus {
                            box-shadow: none;
                            border: none;
                        }
                        div {
                            margin-right: 5px;
                            border: none;
                            border-radius: 10px;
                        }
                        .p-dropdown-label {
                            @include roboto-normal-font;
                            font-size: 16px;
                            line-height: 16px;
                            padding-top: 15px;
                            font-weight: 400;
                        }
                        .p-clickable.pi-chevron-down:before {
                            color: #000000;
                        }
                    }
                }
                .subtitle {
                    color: #000000;
                }
            }
        }
    }
}

.address-page .discount-block {
    margin-top: 15px;
}