
body { 
    margin: 0;
    @include roboto-normal-font;
    

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    /* Change the white to any color ;) */
    /*input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px #FFF2C4 inset !important;
    }*/
    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    #root {
        color: $text-color-default;
        
        .page-container {
            background: $background-color;
            //background-image: url('../img/bg.svg');
            background-image: url('../img/bg_reversed.svg');
            min-height: 100vh;
            padding-top: 60px;
        }
        
        .hide {
            display: none!important;
        }
    }

    // button colors
    .p-button {
/*        background: $text-color-yellow;
        border-color: $text-color-yellow;
        height: 50px;
        &:enabled:hover {
            background: $hover-color-yellow;
            border-color: $hover-color-yellow;
        }*/
        @include primary-button;
    }
    
    .p-checkbox {
        &:not(.p-checkbox-disabled) .p-checkbox-box {
            &.p-highlight {
                background-color: $text-color-yellow;
                background: $text-color-yellow;
            }
            &.p-highlight:hover {
                background: $hover-color-yellow;
                border-color: $hover-color-yellow;
            }
            &:hover {
                background: $hover-color-yellow;
                border-color: $hover-color-yellow;
            }
            &.p-focus {
                box-shadow: none;
            }
        }
    }

    .link {
        color: #402D28;
        font-weight: 400;
        text-decoration: underline;
    }
}