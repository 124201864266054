
.progressbar-component {
    display: none;
    margin-top: 15px;
    .progressbar-wrapper {
        margin: auto;
        display: flex;
        justify-content: center;

        .dot {
            height: 13px;
            width: 13px;
            border: 2px solid $text-color-yellow;
            border-radius: 50%;

            &.filled {
                background-color: $text-color-yellow;
            }
        }
        .line {
            height: 1px;
            width: 28px;
            border: 1px solid #EFB601;
            align-self: center;
        }
    }
}

// ProgressSpinner

    @keyframes ui-progress-spinner-color {
        100%,
        0% {
            stroke: $text-color-yellow;
        }
        40% {
            stroke: $text-color-yellow;
        }
        66% {
            stroke: $text-color-yellow;
        }
        80%,
        90% {
            stroke: $text-color-yellow;
        }
    }
