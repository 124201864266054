.discount-list {

    i {
        font-size: 22px!important;
        &.pi-ban {
            color: $error-color!important;
        }
        &.pi-check {
            color: green!important;
        }
    }
}