 // Breakpoint min-width
 @mixin desktop {
    @media (min-width: #{$bp-desktop}) {
        @content;
    }
 }
  // Breakpoint min-width
  @mixin tablets-and-phones {
    @media (max-width: #{$bp-desktop}) {
        @content;
    }
 }

 @mixin phones {
   @media (max-width: 690px) {
       @content;
   }
}

 @mixin page-title {
    @include sansita-swashed-font;
    color: $text-color-white;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 36px;
    text-align: center;
    margin: 15px auto;
    font-weight: 400;
 } 

 @mixin textarea-placeholder-color {
    textarea::placeholder,
    textarea::-moz-placeholder,  /* Firefox 19+ */
    textarea:-moz-placeholder,  /* Firefox 18- */
    textarea:-ms-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: #A08468;
    }
 }

 @mixin primary-button {
    text-align: center;
    margin-top: 36px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    height: 50px;
    width: 100%;
    background: $text-color-yellow;
    border-color: $text-color-yellow;
    box-shadow: none;

    &:enabled:hover {
        background: $hover-color-yellow;
        border-color: $hover-color-yellow;
    }
    &:disabled {
        background-color: $background-color;
        border: 2px solid $text-color-light-brown;
        .p-button-label {
            color: $text-color-light-brown;
        }
    }
    &:focus{
        box-shadow: none;
    }
 }

 @mixin kl-inputtext {
    width: 100%;
    border-radius: 10px;
    height: 50px;
    //background-color: $background-color-yellow!important;

    color: #000000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    padding-top: 19px;
    padding-left: 20.5px;
    border: 2px solid #FFFFFF;
    &#message {
        height: auto;
    }
    &:enabled:hover {
        border: 2px solid $calendar-date-border;
        box-shadow: none;
    }
    &:enabled:focus{
        border: 2px solid $hover-color-yellow;
        box-shadow: none;
    }
 }