.login-container{
    > div {
        top: 50%;
        position: absolute;
        transform: translate(0%, -90%);
        
        .login-box {
            background-color: #fff;
            border-radius: 3px;
            padding-top: 20px;

            .row {
                width: 100%;
                padding: 10px 20px;
                button, input {
                    width: inherit;
                }
                &.login-error {
                    padding: 0 20px;
                    p {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}