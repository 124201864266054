.admin-order-form {
    .general span  {
        font-weight: 600;
        font-size: 16px;
    }
    .addresses,
    .order_items { 
        label.label{
            display: inline-block;
        }
    }
    .addresses ~ .addresses,
    .order_items ~ .order_items {
        label.label{
            display: none;
        }
        /* default, or inherited from parent div */ 
    }
    .order_items {
        .p-col-2 {
            width: 33.3333%;
            input {
                width: 100%;
            }
        }
        .p-col-2 ~ .p-col-2 {
            width: 16.6667%;
        }
    }
}