
/** Notifications */

.notification-wrapper {
    position: relative;
    top: 0;
    display: block;
    z-index: 100000;
    margin: 12px 14px;

    .notification-row {
        margin-top: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: $background-color;
        width: 100%;
        padding: 15px;
        padding-right: 60px;
        position: relative;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        border-radius: 4px;
    
        span {
            text-decoration: underline;
            cursor: pointer;
        }

       .close-button {
            right: 23px;
            top: 10px;
            position: absolute;
            background-color: transparent;
            width: 25px;
            height: 25px;
            cursor: pointer;
            z-index: 9;
            display: block;
        
            &:before,
            &:after {
                content: '';
                position: absolute;    
                width: 21px;
                height: 2px;
                background-color: $background-color;
                border-radius: 1px;
                top: 16px;
                box-shadow: 0 0 1px 0 $background-color;
            }
            &:before {
                transform: rotate(45deg);
                left: 2px;
            }
            &:after {
                transform: rotate(-45deg);
                right: 2px;
            }
        }

        &.URGENT {
            background-color:$color-light-blue;
        }

        &.NORMAL {
            background-color: $color-light-blue;
        }

        &.INFO {
            background-color: $text-color-yellow;
        }
    }

}