.p-dialog.p-component {
    max-width: 40vw;
    background-color: $background-color-yellow;
    .p-dialog-header,
    .p-dialog-content,
    .p-dialog-footer {
        color: $background-color;
        background-color: $background-color-yellow;
        padding-left: 50px;
        padding-right: 50px;
    }
    // Header
    .p-dialog-header {
        text-align: center;
        padding-top: 30px;
        padding-bottom: 40px;
        position: relative;

        &:after {
            content: url("../img/Star_grey.svg");
            left: 50%;
            bottom: 5px;
            position: absolute;
            right: 50%;
        }

        .p-dialog-title {
            @include sansita-swashed-font;
            font-size: 36px;
            letter-spacing: -0.81px;
            line-height: 48px;
            margin: auto;
        }
        .p-dialog-header-icons {
            position: absolute;
            top: 20px;
            right: 20px;

            .p-dialog-header-icon {
                &:enabled:hover {
                    background: transparent;
                }
                &:focus {
                    box-shadow: none;
                }
                .p-dialog-header-close-icon {
                    font-family: 'Arial';
                    font-size: 30px;
                    font-weight: 200;
                    color: $background-color;
                    &:before{
                        content: url('../img/icon_close.svg');
                    }
                }
            }
        }
    }
    
    // Content
    .p-dialog-content {
        @include roboto-normal-font;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.33px;
        font-weight: 500;
        color: $background-color;
        text-align: center;
    }

    // Footer 
    .p-dialog-footer {
        padding-bottom: 50px;
        text-align: center;
        button {
            @include primary-button;
        }
    }



    @include tablets-and-phones {
        max-width: 90%;
        .p-dialog-header,
        .p-dialog-content,
        .p-dialog-footer {
            padding-left: 25px;
            padding-right: 25px;
        }
        .p-dialog-header {
            padding: 15px;
            .p-dialog-title {
                font-size: 30px;
            }

        }
    }
    &.video-guide-dialog {

        @include tablets-and-phones {
            .p-dialog-content {
                padding: 0;
            }
            .p-dialog-footer {
                padding: 0 15px 15px;
            }
        }
    }
}
// SHOPPING MALL EDITIONS
.p-dialog.p-component.shopping-mall-list {
    max-width: 80vw;
    background-color: $background-color-yellow-light;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    .p-dialog-header,
    .p-dialog-content,
    .p-dialog-footer {
        color: $background-color;
        background-color: $background-color-yellow-light;
        padding-left: 50px;
        padding-right: 50px;
    }
    .p-dialog-header-icons {
        display: none;
    }
    .mall-logo-wrapper{
        &:not(:last-child){
            margin-bottom: 20px;
        }
        border-style: dotted;
        border-width: 4px;
        border-color: $background-color-yellow-light;
        &:not(.itis, .muu-ostoskeskus) {
            opacity: 0.9;
        }
        &:hover {
            border-color: $background-color;
            cursor: pointer;
        }
        &.muu-ostoskeskus {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            span {
                color: $text-color-default;
                padding-left: 10px;
            }
        }
    } 
}