
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600&family=Sansita+Swashed:wght@300;400;600&display=swap');

@mixin roboto-normal-font {
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    color: #000000;
  }


  @mixin sansita-swashed-font {
    font-family: 'Sansita Swashed', cursive;
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
    color: #000000;
  }