
.header-wrapper {
    background-color: $header-back-color;
    .header-content{
        height: 60px;
        padding-left: 30px;
        background-color: #2C2221;
        position: absolute;
        top: 0;
        width: 100%;

        .header-logo {
            width: 126px;
            cursor: pointer;
        }
        .language-button {
            height: 20px;
            width: 35px;
            padding: 0;
            right: 35px;
            position: absolute;
            margin-top: 20px;
            border-radius: 5px;
            @include tablets-and-phones {
                height: 16px;
                width: 27px;
                right: 10px;
            }

        }   
    }
}