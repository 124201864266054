
// CreatePayment page
.create-payment-wrapper {
    padding-top: 45px;

    .page-title {
        @include page-title;
    }
    .isLoading-spinner {
        margin: auto;
    }
    .payment-wrapper {
        max-width: 650px;
        margin: auto;

        .provider-forms {
            flex-wrap: wrap;
            
            .provider-form {
                //margin: auto;
                width: fit-content;
                padding: 5px;
                &.hide,
                button.hide {
                    display:none;
                }

                button {
                    height: 93px;
                    width: 140px;
                    margin: 5px;
                    background-color: $background-color-yellow;
                    border-radius: 10px;
                    border: none;
                    &:hover {
                        background-color: $text-color-light-brown;
                    }
                }
            }
        }
    }
    .error-message {
        @include roboto-normal-font;
        span {
            color: $error-color;
        }
        color: $text-color-white;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        margin: 15px; 
    }
    .payment-terms {
        max-width: 650px;
        margin: auto;
        div, a {
            @include roboto-normal-font;
            color: #FFFFFF;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 21px;
            text-align: center;
            margin: 26px auto 100px; 
        }
    }
}

/** success page */
.not-found-page,
.success-payment-page {

    .page-title {
        @include page-title;
        margin-top: 47px;
        margin-bottom: 78px;
    }
    .page-content {
        text-align: center;
        p {
            @include roboto-normal-font;   
            max-width: 600px;
            color: #FFFFFF;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 21px;
            margin: 55px auto 34px;
            &.not-found-message {
                font-size: 20px;
                font-weight: 600;
                line-height: 31px;
            }
        }
        button {
            @include primary-button;
            margin-top: 0px;
            width: 255px;
            margin-bottom: 70px;
        }
    }
}


.success-payment-page {
    li {
        list-style: none;
    }
    .page-content {
        font-weight: 400;
        h2 {
            font-weight: 600;
            color: #FFFFFF;
            font-size: 18px;
            text-align: left;

            max-width: 720px;
            margin: 25px auto 25px;
            text-align: left;
        }
        p {
            &:nth-of-type(1) {
                margin-top: 50px;
            }
            &:last-of-type {
                margin-bottom: 50px;
            }
            max-width: 720px;
            font-size: 14px;
            margin: 25px auto 25px;
            text-align: left;

            &.yellow {
                //color: $success-page-yellow;
                font-size: 16px;
            }
            img.divider {
                height: 18px;
                width: 18px;
                display: block;
                margin: 25px auto 35px;
            }

            span {
                color: #FFFFFF;
                text-decoration: underline;
                cursor: pointer; 
            }
        }
        img.spatula-img {
            zoom: 0.5;
        }
    }
}