// .footer-wrapper includes action button and price of order in home page
.footer-wrapper {
    margin-top: 56px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    #footer {

        height: 56px;
        background-color: black;
        color: $text-color-light-yellow;
        font-size: 16px;
        font-weight: 600;

        .left {
            float:left;
            padding: 8px 0 0 26px;
            &.mall-footer {
                line-height: 42px;
            }
        }
        span {
            &.discount-applied {
                font-size: 14px;
                text-decoration: line-through;
            }
            &.vat {
                font-size: 9px;
                line-height: 16px;
            }
        }


        .right {
            float: right;
            padding: 8px 11px 0 0 ;
            button.p-button.p-component {
                margin-top: 0px;
                height: 40px;
                padding-left: 46px;
                padding-right: 46px;
                letter-spacing: 0;
                line-height: 21px;
                text-align: center;
            }
        }
        .kotitalousvahennys {

            font-size: 11px;
            letter-spacing: 0;
            line-height: 15px;
            span {
                margin-left: 0px;
                font-weight: 300;
                &.hdc-link {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    @include desktop {
        display: none;
    }

    .discount-price #counter {
        margin-right: 5px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        text-align: right;
        height: 16px;
    }
}

// this is actual footer
.new-footer {
    background-color: $footer-background-color;
    min-height: 60px;
    min-width: 100%;
    padding: 20px;
    padding-top: 25px;

    .icon-text {
        display: inline-block;
        margin-right: 30px;
        img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
            padding-bottom: 5px;
            vertical-align: middle;
        }
        span,
        span a {
            text-decoration: none;
            // Temporar (replace Og values) lightening to avoid too many calls 15/09/22 Olli
            font-size: 14px;
            font-weight: 250;
            // font-size: 16px; // Og value
            // font-weight: 400; // Og value
            color: $text-color-yellow;
        }
    }
    .right {
        float: right;
        position: relative;
        .AAA {
            height: 30px;
            margin-left: 20px;
        }
        .SV {
            height: 50px;
            margin-left: 20px;
            position: absolute;
            left: -55px;
            top: -15px;
        }
    }

    @include tablets-and-phones {
        padding-top: 30px;
        .icon-text {
            display: block;
            text-align: center;
            margin-bottom: 15px;
            img {
                margin-left: 20px;
            }
        }
        .right {
            text-align: center;
            float: none;
            margin-bottom: 110px;

            .AAA {
                margin-top: 25px;
                height: 45px;
            }
            .SV {
                height: 50px;
                margin-left: 20px;
                position: relative;
                display: inline-block;
                left: 0;
                top: 0;
            }
        }
    }
}

